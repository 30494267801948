import { Request2 } from "./Request";
import config from "../client/data/config.js";

export const ContactService = {
  async getNewContactResume(jobTagId) {
    const endpoint = config.clientTagId + "/" + jobTagId;
    try {
      let res = await Request2.get("contacts/resumes", endpoint);
      return res;
    } catch (e) {
      console.error(e);
      return e;
    }
  },

  async sendContactApplication(jobTagId, resumeToken, resumeInfos) {
    const endpoint = config.clientTagId + "/" + jobTagId + "/" + resumeToken;
    const formData = resumeInfos;
    try {
      let res = await Request2.post("contacts/resumes", endpoint, formData);
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  async getContactResume(jobTagId, resumeId) {
    const endpoint = config.clientTagId + "/" + jobTagId + "/" + resumeId;
    try {
      let res = await Request2.get("contacts/resumes", endpoint);
      return res;
    } catch (e) {
      console.error(e);
      return e;
    }
  },
};
