import PropTypes from "prop-types"
import React from "react"
import { $Footer } from "./SC_Footer"
import { ReactSVG } from "react-svg"
import { Link } from "gatsby"

const Footer = ({ siteTitle, siteUrl, footer, footerMap, footerAdress }) => {
  return (
    <$Footer id="contact">
      <div className={`info ${footerAdress === false ? "none" : "flex"} `}>
        <div className="inner">
          <ReactSVG className="logo" src={`../../../SVG/logo.svg`}></ReactSVG>
          <div className="contact">
            <h6>Centre de recrutement</h6>
            <a href="tel:+1-844-706-1997">
              <span>SMS ou Appel: 1-844-706-1997</span>{" "}
            </a>
            <a href="mailto:cv@stas.com" target="_blank">
              <span> Email: cv@stas.com </span>
            </a>
          </div>

          <div className="speak-with-us">
            <h6>Parle avec un recruteur:</h6>
            <span>Lundi au vendredi 8h à 20h et</span>
            <span>Samedi de 9h à 16h</span>
            <div className="social-content">
              <a href="https://www.facebook.com/stasinc">
                <ReactSVG
                  className="facebook"
                  src={`../../../SVG/Trace_642.svg`}
                ></ReactSVG>
              </a>
              <a href="https://www.linkedin.com/company/stas-inc-/">
                <ReactSVG
                  className="linkedIn"
                  src={`../../../SVG/Groupe_737.svg`}
                ></ReactSVG>
              </a>
            </div>
          </div>
          <div className="address">
            <h6>Adresse:</h6>
            <span>622 Rue des Actionnaires</span>
            <span>Chicoutimi, QC G7J 5A9</span>
          </div>
        </div>
      </div>
      <div className={`map ${footerMap === false ? "none" : "flex"} `}>
        <div className="Overlay"></div>
        <ReactSVG
          className="alveolOverMap"
          src={`../../../SVG/alveolOverMap.svg`}
        ></ReactSVG>
      </div>
      <div
        className={`copyright-container ${
          footerAdress === false ? "define" : ""
        }`}
      >
        {/* {footerMap === false ? (
          ""
        ) : (
          <Link to="/pre">
            <span className="ref">Accès employé - Référencement</span>
          </Link>
        )} */}
        {footerMap === false ? (
          ""
        ) : (
          <div className="hexFooter">
            <Link to="/pre">
              <ReactSVG
                className="hex"
                src="../../../../SVG/Trace_853.svg"
              ></ReactSVG>
              <span className="ref">
                Accès employé
                <br />
                Référencement
              </span>
            </Link>
          </div>
        )}
        <span className="copyright">
          © 2023 STAS inc. - Tous droits réservés
        </span>
      </div>
    </$Footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteUrl: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteUrl: `#`,
}

export default Footer
