import React, { useState } from "react";
import ContentBlock from "../../../dependentComponent/contentblock/ContentBlock";
import { Container, Row, Col, Form } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  TextField,
} from "@material-ui/core";
import genericTagHelper from "../../../../helpers/api/genericTagHelper";
import "./styles.scss";

const StepForm = (props) => (
  <Form className="application-form padding-15px">
    <Row className="">
      <Col sm={12}>
        <EnglishLevel
          contactEnglishLevel={props.contactEnglishLevel}
          setApplicationData={props.setApplicationData}
        />
        <ContactTraining
          contactFormationTags={props.applicationData.normaLizedFormationTags}
          setApplicationData={props.setApplicationData}
        />
        <ContactProfessionalSkills
          contactExperienceTags={props.applicationData.normaLizedExperienceTags}
          setApplicationData={props.setApplicationData}
        />
        <Consent setContactConsent={props.setContactConsent} />
      </Col>
    </Row>
  </Form>
);

function EnglishLevel(props) {
  const [englishLevel, setEnglishLevel] = useState(
    props.contactEnglishLevel ? props.contactEnglishLevel : "1631"
  );
  return (
    <div className="marginbottom-md languageLevel-container">
      <label htmlFor="basic-url">Niveau d'anglais</label>
      <FormControl component="fieldset" className="lang-container">
        <div className="languageLevel">
          <label className="float-left">Aucun</label>
          <label className="intermediate-level">Intermédiaire</label>
          <label className="float-right">Bilingue</label>
        </div>

        <RadioGroup
          className="button-lang"
          row
          aria-label="englishLevel"
          name="englishLevel"
          defaultValue={englishLevel}
          onChange={(e) => {
            setEnglishLevel(e.target.value);
            props.setApplicationData("languageTags", [e.target.value]);
          }}
        >
          <FormControlLabel
            value="1631"
            control={<Radio color="primary" />}
            label="1"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="1623"
            control={<Radio color="primary" />}
            label="2"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="1625"
            control={<Radio color="primary" />}
            label="3"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="1627"
            control={<Radio color="primary" />}
            label="4"
            labelPlacement="bottom"
          />
          <FormControlLabel
            value="1629"
            control={<Radio color="primary" />}
            label="5"
            labelPlacement="bottom"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

function ContactTraining(props) {
  const [formationTags] = useState(
    props.contactFormationTags ? props.contactFormationTags : []
  );
  return (
    <div className="marginbottom-md">
      <label htmlFor="basic-url">Formations et/ou Diplômes</label>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={allFormationTags}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        defaultValue={formationTags}
        getOptionSelected={(option, value) => option.tag === value.tag}
        onChange={(event, values) => {
          const contactFormationTags = genericTagHelper.getBuildTagList(values);
          props.setApplicationData("formationTags", contactFormationTags);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Tapez un mot clé ici pour activer la recherche rapide"
          />
        )}
      />
    </div>
  );
}

function ContactProfessionalSkills(props) {
  const [experienceTags, setExperienceTags] = useState(
    props.contactExperienceTags ? props.contactExperienceTags : []
  );

  return (
    <div className="marginbottom-md">
      <label htmlFor="basic-url">Expérience professionnelle</label>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={allExperienceTags}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        defaultValue={experienceTags}
        getOptionSelected={(option, value) => option.tag === value.tag}
        onChange={(event, values) => {
          const contactExperienceTags =
            genericTagHelper.getBuildTagList(values);
          props.setApplicationData("experienceTags", contactExperienceTags);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Tapez un mot clé ici pour activer la recherche rapide"
          />
        )}
      />
    </div>
  );
}

const consentText =
  "Je consens à ce que STAS Inc et/ou ses fournisseurs de service de recrutement puissent me contacter via courriel et message texte (si je donne mon numéro de cellulaire) pour me transmettre de l'information concernant le traitement de ma candidature ou des offres d'emploi. Il est possible de retirer son consentement à tout moment.";

function Consent(props) {
  const [contactConsent, setContactConsent] = React.useState(
    props.isContactConsentChecked ? props.isContactConsentChecked : false
  );

  return (
    <Row className="marginbottom-md margintop-md">
      <Col sm={1}>
        <FormControlLabel
          control={
            <Checkbox
              name="application-consent"
              color="primary"
              checked={contactConsent}
              onChange={(event) => {
                setContactConsent(event.target.checked);
                props.setContactConsent(event.target.checked);
              }}
            />
          }
        />
      </Col>
      <Col sm={11} className="padding0 consent-block">
        <ContentBlock
          title={consentText}
          isDescriptionRequiered={false}
          titleSize={"10px !important"}
          titleFontWeight={"400"}
        />
      </Col>
    </Row>
  );
}

const allFormationTags =
  genericTagHelper.getNormalizedTagList("FORMATION_TAGS");
const allExperienceTags =
  genericTagHelper.getNormalizedTagList("EXPERIENCE_TAGS");

function ApplicationFormStep2(props) {
  const instructionText =
    '<div class="" style="font-size: 1.0rem;font-weight: 400;">Minute... <br>On a presque terminé! <p style="font-size: 13px;">Peux-tu nous en dire un peu plus sur toi...</p><p></p></div>';
  const contactEnglishLevel =
    props.applicationData.normaLizedLanguageTags.length === 0
      ? "1631"
      : props.applicationData.normaLizedLanguageTags[0].tag;

  return (
    <Container className="offer-detail-container" fluid>
      <Row>
        {/* <Col sm={4}>
          <ContentBlock description={instructionText} />
        </Col> */}
        <Col sm={12}>
          <StepForm
            applicationData={props.applicationData}
            contactEnglishLevel={contactEnglishLevel}
            isContactConsentChecked={props.isContactConsentChecked}
            setContactConsent={props.setContactConsent}
            setApplicationData={props.setApplicationData}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ApplicationFormStep2;
