import styled from "styled-components"

export const $Header = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  padding: 1em 1em 1em 3em;
  align-items: center;
  position: fixed;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 1px 3px #000;
  justify-content: ${(props) => (props.destailPage ? "center" : "baseline")};

  @media only screen and (max-width: 768px) {
    padding: 1em 1em 1em 2em;
  }
  @media only screen and (max-width: 425px) {
    justify-content: center;
    text-align: center;
  }

  .logo {
    div {
      svg {
        height: auto;
        width: 8em;
        @media only screen and (max-width: 870px) {
          width: 6em;
          margin-left: -1em;
        }
        @media only screen and (max-width: 425px) {
          width: 45%;
          margin-left: 0;
        }
      }
    }
  }

  .menu {
    font-family: "roboto", sans-serif;
    position: absolute;
    right: 0;
    .STAS {
      font-weight: bold;
      font-size: 1.2rem;
    }
    a {
      cursor: pointer;
      text-decoration: none;
      font-size: 0.9em;
      margin: 0.6em;
      color: ${(props) =>
        props.menuFont.menuFontColor ? props.menuFont.menuFontColor : "#000"};

      &.button {
        position: relative;
        /* background-color: blue; */
        /* border: 2px solid orange; */
        padding: 0.5em 1em;
        display: inline-block;
        background: url(/SVG/background-button-header.svg);
        background-size: contain;
        background-repeat: no-repeat;

        /* clip-path: polygon(2% 7%, 100% 0%, 100% 70%, 90% 100%, 0% 100%); */
        /* &::before {
          position: absolute;
          right: -1px;
          top: -1px;
          content: "";
          border-top: 10px solid #000;
          border-left: 10px solid transparent;
        }
        &::after {
          position: absolute;
          right: -2px;
          top: -2px;
          content: "";
          border-top: 10px solid white;
          border-left: 10px solid transparent;
        } */
      }

      .button img {
        display: block;
      }
    }

    @media only screen and (max-width: 768px) {
      display: flex;
      right: 0;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      height: auto;
      top: ${(props) => (props.menuState === true ? "0%" : "-500%")};
      opacity: ${(props) => (props.menuState === true ? "1" : "0")};
      transition: opacity 0.5s ease-in-out;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      padding: 2em;
      border-bottom-left-radius: 35px;
    }
  }

  .burger-menu,
  .burger-menu.open {
    display: none;

    @media only screen and (max-width: 768px) {
      display: inline-block;
      cursor: pointer;
      position: absolute;
      right: 1em;
      top: 50%;
      z-index: 1;
      padding: 10px;
      transform: translateY(-50%);
    }
  }

  .burger-menu .bar1,
  .bar2,
  .bar3 {
    width: 1.8rem;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }

  .burger-menu.open .bar1 {
    transform: rotate(-45deg) translate(-2px, 5px);
  }

  .burger-menu.open .bar2 {
    opacity: 0;
  }

  .burger-menu.open .bar3 {
    transform: rotate(45deg) translate(-5px, -8px);
  }
`
