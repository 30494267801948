const TAG_TYPE_LIST = {
    FORMATION_TAGS : "formationTags",
    LANGUAGE_TAGS : "languageTags",
    EXPERIENCE_TAGS : "experienceTags",
}

const ALL_TAG_LIST = {
    "formationTags": {
        "451": "AEC - Gestion de commerces",
        "453": "AEC - Techniques de bioécologie",
        "455": "AEC - Agent de gestion des ressources humaines",
        "457": "AEC - Techniques de génie mécanique",
        "459": "AEC - Archives médicales",
        "461": "AEC - Techniques d'éducation à l'enfance",
        "463": "AEC - Techniques juridiques",
        "465": "AEC - Techniques de procédés chimiques",
        "467": "AEC - Techniques de gestion hôtelière",
        "469": "AEC - Techniques policières",
        "471": "AEC - Techniques de production et de postproduction télévisuelles",
        "473": "AEC - Techniques d'éducation spécialisée",
        "475": "AEC - Actualisation en bureautique",
        "477": "AEC - Techniques de bureautique",
        "479": "AEC - Gestion de la production du vêtement",
        "481": "AEC - Photographie",
        "483": "AEC - Techniques de réadaptation physique",
        "485": "AEC - Techniques professionnelles de musique et chanson",
        "487": "AEC - Conception et dessin assistés par ordinateur  (CDAO)",
        "489": "AEC - Techniques d'hygiène dentaire",
        "491": "AEC - Arts du cirque",
        "493": "AEC - Gestion de projet en communications graphiques",
        "495": "AEC - Techniques de communication dans les médias",
        "497": "AEC - Programmation en commande numérique",
        "499": "AEC - Techniques de recherche sociale",
        "501": "AEC - Techniques de la documentation",
        "503": "AEC - Technologie d'analyses biomédicales",
        "505": "AEC - Administration de réseaux",
        "507": "AEC - Techniques d'inhalothérapie",
        "509": "AEC - Gestion d'un établissement de restauration",
        "511": "AEC - Soins infirmiers",
        "513": "AEC - Techniques de comptabilité et de gestion",
        "515": "AEC - Techniques de sécurité incendie",
        "517": "AEC - Technologie de la géomatique",
        "519": "AEC - Assainissement de l'eau",
        "521": "AEC - Techniques de la logistique du transport",
        "523": "AEC - Techniques d'intégration multimédia",
        "525": "AEC - Soins préhospitaliers d’urgence",
        "527": "AEC - Gestion et exploitation d'entreprise agricole",
        "529": "AEC - Comptabilité financière informatisée",
        "531": "AEC - Techniques de thanatologie",
        "533": "AEC - Technologie de la mécanique du bâtiment",
        "535": "AEC - Techniques d'aménagement et d'urbanisme",
        "537": "AEC - Techniques de laboratoire",
        "539": "AEC - Techniques d'intervention en délinquance",
        "541": "AEC - Commercialisation de la mode",
        "543": "AEC - Graphisme",
        "545": "AEC - Techniques de construction aéronautique",
        "547": "AEC - Techniques de tourisme",
        "549": "AEC - Technologie de la production pharmaceutique",
        "551": "AEC - Techniques de l'informatique",
        "553": "AEC - Techniques d'animation 3D et de synthèse d'images",
        "555": "AEC - Techniques d'intervention en loisir",
        "557": "AEC - Conseil en assurances et en services financiers",
        "559": "AEC - Infographie en prémédia",
        "561": "AEC - Techniques de denturologie",
        "563": "AEC - Technologie de la transformation des produits forestiers",
        "565": "AEC - Techniques de transformation des matériaux composites",
        "567": "AEC - Techniques de maintenance d'aéronefs",
        "569": "AEC - Danse-interprétation",
        "571": "AEC - Techniques du meuble et d'ébénisterie",
        "573": "AEC - Inspection de bâtiments",
        "575": "AEC - Techniques d'aquaculture",
        "577": "AEC - Technologie de l'architecture",
        "579": "AEC - Techniques de design de présentation",
        "581": "AEC - Techniques de transformation des matières plastiques",
        "583": "AEC - Techniques de métiers d'art",
        "585": "AEC - Design de mode",
        "587": "AEC - Techniques du tourisme d'aventure",
        "589": "AEC - Technologie de l'électronique",
        "591": "AEC - Interprétation théâtrale",
        "593": "AEC - Courtage immobilier",
        "595": "AEC - Techniques de design d'intérieur",
        "597": "AEC - Techniques de travail social",
        "599": "AEC - Techniques de pilotage d'aéronefs",
        "601": "AEC - Environnement (hygiène et sécurité au travail)",
        "603": "AEC - Techniques équines",
        "605": "AEC - Technologie de l'électronique industrielle",
        "607": "AEC - Techniques de design industriel",
        "609": "AEC - Techniques d'avionique",
        "611": "AEC - Paysage et commercialisation en horticulture ornementale",
        "613": "BAC - Actuariat",
        "615": "AEC - Technologies des pâtes et papiers",
        "617": "BAC - Administration (ressources humaines)",
        "619": "AEC - Technologie de l'estimation et de l'évaluation en bâtiment",
        "621": "BAC - Architecture paysagiste",
        "623": "BAC - Cinématographie",
        "625": "BAC - Design industriel",
        "627": "BAC - Études géopolitiques",
        "629": "BAC - Administration (entrepreneuriat)",
        "631": "AEC - Théâtre-Production",
        "633": "BAC - Administration des affaires (général)",
        "635": "AEC - Technologie de maintenance industrielle",
        "637": "BAC - Communications (journalisme)",
        "639": "BAC - Art dramatique",
        "641": "BAC - Didactique (art d'enseigner)",
        "643": "BAC - Études urbaines",
        "645": "BAC - Administration (finance)",
        "647": "BAC - Administration publique",
        "649": "ASP - Usinage à commandes numériques",
        "651": "BAC - Communications sociales",
        "653": "AEC - Technologie de radiodiagnostic",
        "655": "BAC - Arts graphiques (communications graphiques)",
        "657": "BAC - Administration (Informatique de gestion et SIO)",
        "659": "BAC - Diététique et nutrition",
        "661": "BAC - Formation des enseignants au préscolaire",
        "663": "BAC - Administration (gestion des risques et assurances)",
        "665": "ASP - Fabrication de moules",
        "667": "BAC - Communications (relations publiques)",
        "669": "AEC - Technologie de systèmes ordinés",
        "671": "BAC - Droit",
        "673": "BAC - Arts plastiques (peinture - dessin - sculpture)",
        "675": "BAC - Administration (gestion internationale)",
        "677": "BAC - Administration (gestion urbaine et immobilière)",
        "679": "BAC - Formation des enseignants au préscolaire et au primaire",
        "681": "BAC - Comptabilité et sciences comptables",
        "683": "ASP - Matriçage",
        "685": "AEC - Technologie des procédés et de la qualité des aliments",
        "687": "BAC - Économie",
        "689": "BAC - Agriculture",
        "691": "BAC - Beaux-arts et arts appliqués",
        "693": "BAC - Administration (logistique et approvisionnement)",
        "695": "BAC - Criminologie",
        "697": "BAC - Formation des enseignants au secondaire",
        "699": "ASP - Outillage",
        "701": "AEC - Technologie du génie civil",
        "703": "BAC - Anglais (général et langue maternelle)",
        "705": "BAC - Économie rurale et agricole",
        "707": "BAC - Design appliqué (céramique - tissage - orfèvrerie - décor)",
        "709": "BAC - Bibliothéconomie et archivistique",
        "711": "BAC - Formation des enseignants de l'enseignement professionnel au secondaire et au collégial",
        "713": "BAC - Administration (management)",
        "715": "ASP - Soudage haute-pression",
        "717": "AEC - Technologie du génie industriel",
        "719": "BAC - Design de l'environnement",
        "721": "BAC - Animation sociale ou communautaire",
        "723": "BAC - Formation des enseignants spécialistes au primaire et au secondaire",
        "725": "BAC - Éducation des adultes et formation permanente",
        "727": "BAC - Biochimie",
        "729": "BAC - Administration (opérations et logistique)",
        "731": "ASP - Représentation",
        "733": "AEC - Technologie forestière",
        "735": "BAC - Anthropologie",
        "737": "BAC - Biologie moléculaire et cellulaire",
        "739": "BAC - Design de produits",
        "741": "BAC - Formation des enseignants spécialistes en adaptation scolaire (orthopédagogie)",
        "743": "BAC - Environnement (qualité du milieu et pollution)",
        "745": "BAC - Administration (marketing)",
        "747": "ASP - Secrétariat juridique",
        "749": "AEC - Technologie minérale",
        "751": "BAC - Architecture",
        "753": "BAC - Français (général et langue maternelle)",
        "755": "BAC - Biophysique",
        "757": "BAC - Design graphique",
        "759": "BAC - Ergothérapie",
        "761": "BAC - Administration (services financiers)",
        "763": "BAC - Génie aérospatial ou aéronautique ou astronautique",
        "765": "ASP - Secrétariat médical",
        "767": "BAC - Géographie",
        "769": "BAC - Génie forestier etsciences du bois (sylviculture)",
        "771": "BAC - Ethnologie et ethnographie",
        "773": "BAC - Chimie",
        "775": "BAC - Français langue seconde",
        "777": "BAC - Linguistique (phonétique - sémantique - philologie)",
        "779": "BAC - Génie agricole et génie rural",
        "781": "BAC - Pédologie (aménagement et conservation des sols)",
        "783": "BAC - Génie géologique",
        "785": "BAC - Géologie (minéralogie - etc.)",
        "787": "BAC - Récréologie",
        "789": "BAC - Urbanisme",
        "791": "BAC - Pharmacologie",
        "793": "BAC - Mathématiques",
        "795": "BAC - Génie agro-forestier",
        "797": "BAC - Périnalité",
        "799": "BAC - Relations industrielles",
        "801": "BAC - Génie géomatique",
        "803": "BAC - Géomatique",
        "805": "BAC - Mathématiques appliquées",
        "807": "BAC - Zoologie",
        "809": "BAC - Génie alimentaire",
        "811": "BAC - Chimie pharmaceutique",
        "813": "BAC - Pharmacie et sciences pharmaceutiques",
        "815": "BAC - Génie industriel et administratif",
        "817": "BAC - Ressources naturelles",
        "819": "BAC - Gestion de la production",
        "821": "BAC - Météorologie et sciences de l'atmosphère",
        "823": "BAC - Zootechnie",
        "825": "BAC - Sciences infirmières et nursing",
        "827": "BAC - Génie biologique et biomédical",
        "829": "BAC - Philosophie",
        "831": "BAC - Santé communautaire et épidémiologie",
        "833": "BAC - Génie informatique et de la construction des ordinateurs",
        "835": "BAC - Microbiologie",
        "837": "CERTIFICAT - Administration générale",
        "839": "BAC - Gestion et administration des entreprises",
        "841": "BAC - Sciences physiques",
        "843": "BAC - Génie biotechnologique",
        "845": "BAC - Physiothérapie",
        "847": "BAC - Sciences de la terre",
        "849": "BAC - Génie mécanique",
        "851": "CERTIFICAT - Analyse des systèmes d'affaires",
        "853": "BAC - Mouvement expressif - danse - mime - rythmique",
        "855": "BAC - Génie chimique",
        "857": "BAC - Histoire",
        "859": "BAC - Sciences politiques",
        "861": "BAC - Sciences de l'activité physique",
        "863": "BAC - Musique",
        "865": "BAC - Phytotechnie",
        "867": "CERTIFICAT - Analyse financière",
        "869": "BAC - Génie métallurgique et des matériaux",
        "871": "BAC - Histoire de l'art",
        "873": "BAC - Sciences religieuses",
        "875": "BAC - Génie civil de la construction et du transport",
        "877": "BAC - Sciences de l'informatique",
        "879": "BAC - Opérations bancaires et finance",
        "881": "BAC - Génie minier",
        "883": "BAC - Probabilités et statistiques",
        "885": "CERTIFICAT - Comptabilité",
        "887": "BAC - Service/Sciences sociaux",
        "889": "BAC - Génie des pâtes et papiers",
        "891": "BAC - Humanités gréco-latines et archéologie classique",
        "893": "BAC - Sciences domestiques",
        "895": "BAC - Psycho-éducation",
        "897": "CERTIFICAT - Assurance et rentres collectives",
        "899": "BAC - Génie nucléaire",
        "901": "BAC - Orientation - information scolaire et professionnelle",
        "903": "BAC - Génie électrique et électronique des communications",
        "905": "BAC - Langues et littératures françaises ou anglaises",
        "907": "BAC - Sexologie",
        "909": "BAC - Sciences et technologie des aliments",
        "911": "BAC - Psychologie",
        "913": "BAC - Orthophonie et audiologie",
        "915": "CERTIFICAT - Biotechnologies",
        "917": "BAC - Langues et littératures modernes autres que le français et l'anglais",
        "919": "BAC - Génie physique",
        "921": "CERTIFICAT - Communication publique",
        "923": "BAC - Sociologie",
        "925": "BAC - Sciences fondamentales et sciences appliquées de la santé",
        "927": "BAC - Recherche opérationnelle",
        "929": "CERTIFICAT - Biologie médicale",
        "931": "CERTIFICAT - Littérature",
        "933": "BAC - Traduction",
        "935": "DEC - Audioprothèse",
        "937": "CERTIFICAT - Création littéraire",
        "939": "DEC - Gestion de la production du vêtement",
        "941": "DEC - Navigation",
        "943": "DEC - Techniques de bioécologie",
        "945": "DEC - Techniques de génie chimique",
        "947": "CERTIFICAT - Marketing",
        "949": "DEC - Biotechnologies",
        "951": "DEC - Techniques de métiers d'art",
        "953": "DEC - Gestion de projet en communications graphiques",
        "955": "DEC - Paysage et commercialisation en horticulture ornementale",
        "957": "CERTIFICAT - Développement durable",
        "959": "DEC - Techniques de génie mécanique",
        "961": "DEC - Techniques de bureautique",
        "963": "CERTIFICAT - Relations du travail",
        "965": "DEC - Techniques de muséologie",
        "967": "DEC - Gestion des réseaux informatiques",
        "969": "DEC - Photographie",
        "971": "DEC - Chimie analytique",
        "973": "CERTIFICAT - Droit",
        "975": "DEC - Techniques de génie mécanique de marine",
        "977": "DEC - Techniques de communication dans les médias",
        "979": "DEC - Techniques de pilotage d'aéronefs",
        "981": "DEC - Soins infirmiers",
        "983": "DEC - Gestion d'un établissement de restauration",
        "985": "CERTIFICAT - Relations industrielles",
        "987": "DEC - Commercialisation de la mode",
        "989": "CERTIFICAT - Entrepreneuriat et gestion de PME",
        "991": "DEC - Techniques de gestion hôtelière",
        "993": "DEC - Techniques de comptabilité et de gestion",
        "995": "DEC - Techniques de procédés chimiques",
        "997": "DEC - Soins préhospitaliers d'urgence",
        "999": "DEC - Gestion et exploitation d'entreprise agricole",
        "1001": "DEC - Conseil en assurances et en services financiers",
        "1003": "CERTIFICAT - Sciences de la consommation",
        "1005": "CERTIFICAT - Gestion de la chaîne d'approvisionnement",
        "1007": "DEC - Techniques de la documentation",
        "1009": "DEC - Techniques de construction aéronautique",
        "1011": "DEC - Techniques d'aménagement cynégétique et halieutique",
        "1013": "DEC - Techniques de production et de postproduction télévisuelles",
        "1015": "DEC - Danse-interprétation",
        "1017": "DEC - Graphisme",
        "1019": "CERTIFICAT - Gestion des ressources humaines",
        "1021": "CERTIFICAT - Services financiers",
        "1023": "DEC - Techniques de la logistique du transport",
        "1025": "DEC - Techniques de denturologie",
        "1027": "DEC - Techniques de prothèses dentaires",
        "1029": "DEC - Techniques d'aménagement et d'urbanisme",
        "1031": "DEC - Infographie en préimpression",
        "1033": "DEC - Design de mode",
        "1035": "CERTIFICAT - Gestion urbaine et immobilière",
        "1037": "DEC - Acupuncture",
        "1039": "DEC - Techniques de laboratoire",
        "1041": "DEC - Techniques de réadaptation physique",
        "1043": "DEC - Techniques d'animation 3D et de synthèse d'images",
        "1045": "DEC - Techniques de design de présentation",
        "1047": "DEC - Techniques de l'impression",
        "1049": "DEC - Techniques de recherche sociale",
        "1051": "DEC - Infographie en prémédia",
        "1053": "DEC - Dessin animé",
        "1055": "DEC - Archives médicales",
        "1057": "CERTIFICAT - Informatique",
        "1059": "DEC - Techniques de l'informatique",
        "1061": "DEC - Informatique industrielle",
        "1063": "DEC - Techniques de design d'intérieur",
        "1065": "DEC - Techniques de santé animale",
        "1067": "DEC - Environnement (hygiène et sécurité au travail)",
        "1069": "DEC - Techniques d'aquaculture",
        "1071": "DEC - Arts du cirque",
        "1073": "CERTIFICAT - Journalisme",
        "1075": "DEC - Techniques de design industriel",
        "1077": "DEC - Techniques d'avionique",
        "1079": "DEC - Techniques de maintenance d'aéronefs",
        "1081": "DEC - Interprétation théâtrale",
        "1083": "DEC - Gestion de commerces",
        "1085": "DEC - Assainissement de l'eau",
        "1087": "DEC - Techniques de sécurité incendie",
        "1089": "DEC - Techniques de thanatologie",
        "1091": "DEC - Technologie des productions animales",
        "1093": "DEC - Techniques policières",
        "1095": "DEC - Techniques de diététique",
        "1097": "DEC - Techniques de tourisme",
        "1099": "DEC - Théâtre-Production",
        "1101": "DEP - Bijouterie-joaillerie",
        "1103": "DEC - Technologie de l'architecture navale",
        "1105": "DEC - Techniques d'intégration multimédia",
        "1107": "DEC - Technologie du génie agromécanique",
        "1109": "DEC - Techniques professionnelles de musique et chanson",
        "1111": "DEP - Abattage manuel et débardage forestier",
        "1113": "DEP - Coiffure",
        "1115": "DEC - Techniques de transformation des matériaux composites",
        "1117": "DEC - Techniques d'intervention en délinquance",
        "1119": "DEP - Boucherie de détail",
        "1121": "DEC - Technologie de l'électronique",
        "1123": "DEC - Technologie d'analyses biomédicales",
        "1125": "DEP - Comptabilité",
        "1127": "DEC - Techniques de transformation des matières plastiques",
        "1129": "DEP - Affûtage",
        "1131": "DEC - Technologie du génie civil",
        "1133": "DEP - Boulangerie",
        "1135": "DEP - Aménagement de la forêt",
        "1137": "DEC - Technologie de la géomatique",
        "1139": "DEP - Conduite de machines de traitement du minerai",
        "1141": "DEC - Techniques d'intervention en loisir",
        "1143": "DEP - Briquetage-maçonnerie",
        "1145": "DEC - Technologie de l'électronique industrielle",
        "1147": "DEP - Aquiculture",
        "1149": "DEC - Technologie de la mécanique du bâtiment",
        "1151": "DEP - Conduite de procédés de traitement de l'eau",
        "1153": "DEC - Technologie du génie industriel",
        "1155": "DEP - Calorifugeage",
        "1157": "DEC - Technologie de l'estimation et de l'évaluation en bâtiment",
        "1159": "DEC - Techniques d'orthèses et de prothèses orthopédiques",
        "1161": "DEC - Techniques de travail social",
        "1163": "DEC - Technologie de la production horticole et de l'environnement",
        "1165": "DEP - Conduite d'engins de chantier",
        "1167": "DEP - Arboriculture-élagage",
        "1169": "DEC - Technologie du génie métallurgique - Option mécano-soudé",
        "1171": "DEC - Technologie de maintenance industrielle",
        "1173": "DEP - Carrelage",
        "1175": "DEC - Techniques d'éducation à l'enfance",
        "1177": "DEC - Technologie de la production pharmaceutique",
        "1179": "DEP - Conduite d'engins de chantier nordique",
        "1181": "DEC - Techniques d'orthèses visuelles",
        "1183": "DEC - Technologie du génie métallurgique - Option transformation",
        "1185": "DEP - Carrosserie",
        "1187": "DEC - Techniques d'éducation spécialisée",
        "1189": "DEP - Arpentage et topographie",
        "1191": "DEC - Techniques du meuble et d'ébénisterie",
        "1193": "DEC - Technologie forestière",
        "1195": "DEP - Charpenterie-menuiserie",
        "1197": "DEC - Technologie de médecine nucléaire",
        "1199": "DEC - Techniques d'électrophysiologie médicale",
        "1201": "DEP - Assistance à la personne à domicile",
        "1203": "DEC - Technologie de la transformation des produits aquatiques",
        "1205": "DEP - Conduite et réglage de machines à mouler",
        "1207": "DEP - Chaudronnerie",
        "1209": "DEC - Technologie de radiodiagnostic",
        "1211": "DEC - Techniques d'hygiène dentaire",
        "1213": "DEC - Technologie de la transformation des produits forestiers",
        "1215": "DEP - Confection de vêtements (Façon tailleur)",
        "1217": "DEC - Techniques du milieu naturel",
        "1219": "DEC - Technologie minérale",
        "1221": "DEC - Technologie de l'architecture",
        "1223": "DEP - Assistance dentaire",
        "1225": "DEC - Techniques d'inhalothérapie",
        "1227": "DEP - Confection de vêtements et d'articles de cuir",
        "1229": "DEC - Technologie de radio-oncologie",
        "1231": "DEC - Techniques du tourisme d'aventure",
        "1233": "DEP - Classement des bois débités",
        "1235": "DEP - Assistance technique en pharmacie",
        "1237": "DEP - Cuisine",
        "1239": "DEC - Technologie de systèmes ordinés",
        "1241": "DEC - Techniques équines",
        "1243": "DEP - Confection sur mesure et retouche",
        "1245": "DEP - Entretien général d'immeubles",
        "1247": "DEC - Technologie physique",
        "1249": "DEC - Techniques juridiques",
        "1251": "DEC - Technologie des procédés et de la qualité des aliments",
        "1253": "DEP - Horlogerie-bijouterie",
        "1255": "DEP - Décoration intérieure et présentation visuelle",
        "1257": "DEP - Esthétique",
        "1259": "DEP - Mécanique automobile",
        "1261": "DEP - Modelage",
        "1263": "DEP - Découpe et transformation du verre",
        "1265": "DEP - Peinture en bâtiment",
        "1267": "DEP - Montage de câbles et de circuits",
        "1269": "DEP - Extraction de minerai",
        "1271": "DEP - Horticulture et jardinerie",
        "1273": "DEP - Dessin de bâtiment",
        "1275": "DEP - Protection et exploitation de territoires fauniques",
        "1277": "DEP - Photographie",
        "1279": "DEC - Technologies des pâtes et papiers",
        "1281": "DEP - Montage de lignes électriques",
        "1283": "DEP - Fabrication de structures métalliques et de métaux ouvrés",
        "1285": "DEP - Réalisation d'aménagements paysagers",
        "1287": "DEP - Mécanique d'ascenseur",
        "1289": "DEP - Plâtrage",
        "1291": "DEP - Montage de structures en aérospatiale",
        "1293": "DEP - Ferblanterie-tôlerie",
        "1295": "DEP - Sciage",
        "1297": "DEP - Dessin de patron",
        "1299": "DEP - Imprimerie",
        "1301": "DEP - Réfrigération",
        "1303": "DEP - Montage mécanique en aérospatiale",
        "1305": "DEP - Finition de meubles",
        "1307": "DEP - Secrétariat",
        "1309": "DEP - Plomberie et chauffage",
        "1311": "DEP - Dessin industriel",
        "1313": "DEP - Fleuristerie",
        "1315": "DEP - Mécanique de machines fixes",
        "1317": "DEP - Serrurerie",
        "1319": "DEP - Pose de revêtements de toiture",
        "1321": "DEP - Montage structural et architectural",
        "1323": "DEP - Régulation de vol",
        "1325": "DEP - Ébénisterie",
        "1327": "DEP - Infographie",
        "1329": "DEP - Service de la restauration",
        "1331": "DEP - Pose de systèmes intérieurs",
        "1333": "DEP - Rembourrage artisanal",
        "1335": "DEP - Opération d'équipements de production",
        "1337": "DEP - Mécanique de protection contre les incendies",
        "1339": "DEP - Service technique d'équipement bureautique",
        "1341": "DEP - Installation de revêtements souples",
        "1343": "DEP - Fonderie",
        "1345": "DEP - Électricité",
        "1347": "DEP - Service-conseil à la clientèle en équipement motorisé",
        "1349": "DEP - Mécanique de véhicules légers",
        "1351": "DEP - Électromécanique de systèmes automatisés",
        "1353": "DEP - Rembourrage industriel",
        "1355": "DEP - Soudage-montage",
        "1357": "DEP - Pâtes et papiers - Opérations",
        "1359": "DEP - Préparation et finition de béton",
        "1361": "DEP - Entretien de bâtiments nordiques",
        "1363": "DEP - Réparation d'appareils au gaz naturel",
        "1365": "DEP - Soutien informatique",
        "1367": "DEP - Forage et dynamitage",
        "1369": "DEP - Installation et entretien de systèmes de sécurité",
        "1371": "DEP - Pâtisserie",
        "1373": "DEP - Tôlerie de précision",
        "1375": "DEP - Réparation d'appareils électroménagers",
        "1377": "DEP - Taille de pierre",
        "1379": "DEP - Mécanique de véhicules lourds routiers",
        "1381": "DEP - Pêche professionnelle",
        "1383": "DEP - Travail sylvicole",
        "1385": "DEP - Production acéricole",
        "1387": "DEP - Installation et fabrication de produits verriers",
        "1389": "DEP - Mécanique d'engins de chantier",
        "1391": "DEP - Grandes cultures",
        "1393": "DOCTORAT - Médecine dentaire",
        "1395": "DEP - Vente de pièces mécaniques et d'accessoires",
        "1397": "DEP - Techniques d'usinage",
        "1399": "DEP - Réparation d'appareils électroniques audiovidéos",
        "1401": "DOCTORAT - Médecine vétérinaire",
        "1403": "DEP - Production animale",
        "1405": "DEP - Vente de produits de quincaillerie",
        "1407": "DEP - Santé (assistance et soins infirmiers)",
        "1409": "DEP - Production horticole",
        "1411": "DOCTORAT - Optométrie",
        "1413": "DEP - Vente de voyages",
        "1415": "MAITRISE - Démographie",
        "1417": "DEP - Mécanique industrielle de construction et d'entretien",
        "1419": "DEP - Installation et réparation d'équipement de télécommunication",
        "1421": "MAITRISE - Génie métallurgique",
        "1423": "MAITRISE - Océanographie",
        "1425": "DOCTORAT - Psychologie",
        "1427": "MAITRISE - Zootechnie",
        "1429": "DEP - Vente-conseil",
        "1431": "MAITRISE - Didactique (art d'enseigner)",
        "1433": "DEP - Mécanique marine",
        "1435": "DEP - Intervention en sécurité incendie",
        "1437": "MAITRISE - Orthophonie et audiologie",
        "1439": "MAITRISE - Administration publique",
        "1441": "DESS - Santé et sécurité",
        "1443": "MAITRISE - Économie rurale et agricole",
        "1445": "MAITRISE - Géographie",
        "1447": "DEP - Mécanique agricole",
        "1449": "MBA - Affaires électroniques",
        "1451": "MAITRISE - Pédagogie universitaire",
        "1453": "MAITRISE - Administration scolaire",
        "1455": "DOCTORAT - Autres professions de la santé",
        "1457": "MAITRISE - Éducation des adultes et formation permanente",
        "1459": "DEP - Mise en oeuvre de matériaux composites",
        "1461": "MAITRISE - Pédologie (aménagement et conservation des sols)",
        "1463": "MAITRISE - Architecture",
        "1465": "MAITRISE - Gestion de projet",
        "1467": "MAITRISE - Physiothérapie",
        "1469": "MAITRISE - Architecture option design urbain",
        "1471": "MBA - Gestion",
        "1473": "MAITRISE - Énergie",
        "1475": "MAITRISE - Psycho-éducation",
        "1477": "DOCTORAT - Chiropratique",
        "1479": "MBA - Gestion de la santé et sécurité",
        "1481": "MAITRISE - Architecture urbaine et aménagement",
        "1483": "MAITRISE - Ergothérapie",
        "1485": "MAITRISE - Gestion des personnes en milieu de travail",
        "1487": "DOCTORAT - Médecine",
        "1489": "MAITRISE - Psychologie",
        "1491": "MAITRISE - Bibliothéconomie et archivistique",
        "1493": "MAITRISE - Ethnologie et ethnographie",
        "1495": "MAITRISE - Gestion des ressources humaines",
        "1497": "MBA - Gestion des services de santé",
        "1499": "MAITRISE - Santé communautaire et épidémiologie",
        "1501": "MAITRISE - Gestion des services de santé",
        "1503": "MAITRISE - Fiscalité",
        "1505": "MAITRISE - Sciences fondamentales et appliquées de la santé",
        "1507": "MAITRISE - Formation des enseignants au collégial",
        "1509": "MAITRISE - Hydrologie et sciences de l'eau",
        "1511": "MAITRISE - Zoologie",
        "1513": "MAITRISE - Génétique",
        "1515": "MAITRISE - Littérature comparée",
        "1517": "MAITRISE - Mathématiques appliquées",
        "1519": "MAITRISE - Médecine et chirurgie expérimentales",
        "1521": "DEC - Sciences humaines (tout profil pré-universitaire)",
        "1523": "DEC - Sciences de la nature (tout profil pré-universitaire)",
        "1525": "DES - Diplôme d'études secondaires",
        "1527": "DES - Équivalence d'études secondaires",
        "5979": "BAC - Génie de production automatisé"
    },
    "languageTags": {
        "1621": "Anglais de base",
        "1623": "Anglais fonctionnel",
        "1625": "Anglais intermédiaire",
        "1627": "Anglais avancé",
        "1629": "Bilingue",
        "1631": "Aucun anglais"
    },
    "experienceTags": {
        "1635": "Entreposage et distribution (commis / expédition / chariot élévateur)",
        "1637": "Comptabilité (commis / paie / technicien / recouvrement / payables / recevables)",
        "1639": "Achats (approvisionnement / logistique / gestion inventaire)",
        "1641": "Service à la clientèle / Soutien technique / Prise de commandes",
        "1643": "Mécanique (auto / diesel / chantier / véhicules lourds)",
        "1645": "Soudure et assemblage de structures",
        "1647": "Exploitation et ressources naturelles (génie minier / environnement / génie forestier / agriculture)",
        "1649": "Comptabilité (contrôleur / chef comptable / certification / vérification)",
        "1651": "Planification de production",
        "1653": "Achats (magasinier)",
        "1655": "Conception (génie mécanique / électronique / physique)",
        "1657": "Soutien administratif (commis / réceptionniste / secrétariat / saisie de données / classement / documentation)",
        "1659": "Finances (assurances / analyse financière / placements / actuariat)",
        "1661": "Recherche et développement (laboratoires / sciences / pharmaceutique)",
        "1663": "Adjointe administrative / Secrétaire de direction",
        "1665": "Gestion de projet (construction / ingénierie)",
        "1667": "Usinage (programmation / CNC / machiniste / outillage)",
        "1669": "Recherche et développement (produits / manufacturiers / design industriel)",
        "1671": "Amélioration continue (génie industriel / Lean 6Sigma / Kaizen / optimisation)",
        "1673": "Conception et dessin bâtiment (architecture / génie civil / mécanique du bâtiment / efficacité énergétique / urbanisme)",
        "1675": "Répartitition et logistique du transport",
        "1677": "Gestion de projet (informatique / services / généraliste)",
        "1679": "Ventes et représentation",
        "1681": "Inspection et estimation de bâtiments ou projets de construction",
        "1683": "Contrôle de qualité (Laboratoire / pharmaceutique)",
        "1685": "Ressources humaines - Conseiller (SST / relations de travail / dotation / rémunération / généraliste)",
        "1687": "Assurance qualité (laboratoire ou pharmaceutique)",
        "1689": "Maintenance et mécanique industrielle / instrumentation et contrôle / MMF / Électromécanique",
        "1691": "Contrôle de qualité (manufacturier ou services)",
        "1693": "Ressources humaines - Direction (SST / relations de travail / dotation / rémunération / généraliste)",
        "1695": "Assurance qualité (manufacturier ou services)",
        "1697": "Manipulations de laboratoire (technicien)",
        "1699": "Développement organisationnel (DO / formation / intégration)",
        "1701": "Carrosserie (peinture / débosselage)",
        "1703": "Ressources humaines - Technicien (SST / relations de travail / dotation / rémunération / généraliste)",
        "1705": "Direction des opérations / Direction de production / Direction d'usine",
        "1707": "Manoeuvre de production (journalier / opérateurs)",
        "1709": "Direction générale (DG / Vice-président / Président)",
        "1711": "Chef d'équipe / Superviseur / Contremaître (manufacturier ou sur terrain)",
        "1713": "Santé (Soins infirmiers / optométrie / dietétique / autres soins)",
        "1715": "Manœuvre de production (spécifiquement en pharmaceutique)",
        "1717": "Secrétariat spécialisé (dentaire / médical / juridique)",
        "1719": "Chef d'équipe / Superviseur / Contremaître (spécifiquement en pharmaceutique)",
        "1721": "Direction municipale (directeur / secrétaire trésorier / urbanisme / travaux publics)",
        "1723": "Communication (traduction / rédaction / marketing / graphisme / événementiel)",
        "3299": "Restauration",
        "5797": "Chef d'équipe / Superviseur / Contremaître (en bureau ou au détail ou restauration)",
        "7812": "Expert en sinistres",
        "7996": "Entretien ménager",
        "8388": "Entretien/réparation/maintenance de bâtiments"
    }
};

const genericTagHelper = {
    getNormalizedTagList(tagNameToNormalize, tagList) {
        let keys = null;
        if(tagList === undefined) {
            tagList = ALL_TAG_LIST;
            const tagTypeToNormalize = TAG_TYPE_LIST[tagNameToNormalize];
            tagList = tagList[tagTypeToNormalize];
            keys = Object.keys(tagList);
        } else {
            keys = Object.keys(tagList);
        }
        
        if(!keys) throw new TypeError('tag list keys cannot be null');
        let normalizedTagList = [];
        for(var i=0; i<keys.length; i++){
            var key = keys[i];
            normalizedTagList.push({'tag': key, 'label': tagList[key]})
        }
        return normalizedTagList;
    },
    
    getBuildTagList(normalizedTagList) {
        const tagList = []; 
        for ( var i in normalizedTagList) {
            tagList[i] = normalizedTagList[i].tag;
        }
        return tagList;
    }
};

export default genericTagHelper;