import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "./styles/reset.scss";
import "./styles/layout.scss";
import clientData from "../client/data/data.json";
import NosUsine from "../client/data/nosUsine";
import Application from "../components/body/offerApplication";
import getUrlParameter from "./dependentComponent/functions/getParamsValue";

const BaseLayout = ({ children, destailPage, footerMap, footerAdress }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [offerApplicationState, setOfferApplicationState] = React.useState(
    false
  );

  const childRef = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div style={{ display: `${isLoading ? "none" : "block"}` }}>
      <Header
        destailPage={destailPage ? destailPage : false}
        clientName={clientData.clientName}
        menuDatas={clientData.menu}
      />
      <div className="layout-container">{children}</div>
      <Application
        // jobId={
        //   emailValue !== ""
        //     ? offerNotSuitableForTheContact_OfferId
        //     : offer.tags
        // }
        // email={emailValue}
        jobId={10688}
        email={""}
        ref={childRef}
        formParams={getUrlParameter("form") ? true : false}
      />
      <Footer
        siteTitle={clientData.siteTitle}
        siteUrl={clientData.siteUrl}
        footer={clientData.footer}
        footerMap={footerMap}
        footerAdress={footerAdress}
      />
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
