import axios from "axios";
import config from "../utils/config.js";
import { authHelper } from "../helpers/authHeader.js";

export const Request = {
  get(service, endpoint, parameters = {}) {
    const uri =
      config.apiUrl +
      (service != null ? "/" + service : "") +
      (endpoint != null ? "/" + endpoint : "");
    return new Promise(function (resolve, reject) {
      axios({
        method: "get",
        url: uri,
        headers: authHelper.authHeader(),
        responseType: "json",
        params: parameters,
        validateStatus: function (status) {
          return status < 300;
        },
      })
        .then((res) => {
          if (res && !res.status) throw new Error("Network Error");
          resolve(res.data);
        })
        .catch((err) => {
          reject({ error: err.data });
        });
    });
  },

  post(service, endpoint, formObj = {}) {
    return new Promise((resolve, reject) => {
      return axios({
        method: "post",
        url:
          config.apiUrl +
          (service != null ? "/" + service : "") +
          (endpoint != null ? "/" + endpoint : ""),
        headers: authHelper.authHeader(),
        responseType: "json",
        data: formObj,
        validateStatus: function (status) {
          return status < 300;
        },
      })
        .then((res) => {
          if (res && !res.status) throw new Error("Network Error");
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export const Request2 = {
  get(service, endpoint, parameters = {}) {
    const uri =
      config.apiUrl2 +
      (service != null ? "/" + service : "") +
      (endpoint != null ? "/" + endpoint : "");
    return new Promise(function (resolve, reject) {
      axios({
        method: "get",
        url: uri,
        headers: authHelper.authHeader(),
        responseType: "json",
        params: parameters,
        validateStatus: function (status) {
          return status < 300;
        },
      })
        .then((res) => {
          if (res && !res.status) throw new Error("Network Error");
          resolve(res.data);
        })
        .catch((err) => {
          reject({ error: err.data });
        });
    });
  },

  post(service, endpoint, formObj = {}) {
    return new Promise((resolve, reject) => {
      return axios({
        method: "post",
        url:
          config.apiUrl2 +
          (service != null ? "/" + service : "") +
          (endpoint != null ? "/" + endpoint : ""),
        headers: authHelper.authHeader(),
        responseType: "json",
        data: formObj,
        validateStatus: function (status) {
          return status < 300;
        },
      })
        .then((res) => {
          if (res && !res.status) throw new Error("Network Error");
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
