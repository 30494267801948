import styled from "styled-components"
export const $Footer = styled.footer`
  width: 100%;
  height: auto;

  .info {
    height: auto;
    width: 100%;
    background-color: #121f48;
    @media only screen and (min-width: 768px) {
      min-width: 768px;
    }
    .inner {
      height: 100%;
      padding: 0 2rem;

      width: 80%;
      min-width: 764px;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      align-items: flex-start;
      color: #fff;
      .logo {
        padding: 4rem 1rem;
        @media only screen and (max-width: 768px) {
          padding: 2rem 1rem;
        }
        svg {
          path {
            fill: #fff;
          }
        }
      }
      .contact,
      .speak-with-us,
      .address {
        a {
          text-decoration: none;
          color: #fff;
          margin: 10px 0px;
        }
        span {
          margin: 5px 0px;
        }
        padding: 4rem 1rem;
        @media only screen and (max-width: 768px) {
          padding: 1rem 1rem;
        }
      }

      .social-content {
        display: flex;
        svg {
          margin-right: 1em;
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        width: 320px;
        min-width: 320px;
        flex-direction: column;
        align-items: baseline;
      }

      @media only screen and (max-width: 500px) {
        min-width: 0px;
      }

      h6 {
        font-size: 1.2em;
        font-weight: bold;
      }
      span {
        display: block;
        font-size: 0.9em;
      }
    }
    &.none {
      display: none;
    }
  }

  .map {
    width: 100%;
    cursor: pointer;
    height: 25em;
    background: url("../../../map-stas.webp") no-repeat center center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
      background: url("../../../map-stas-tablet.webp") no-repeat center center;
      background-size: cover;
      height: 20em;
    }

    @media only screen and (max-width: 425px) {
      background: url("../../../map-stas-mobile.webp") no-repeat center center;
      background-size: cover;
      height: 18em;
    }

    .innerText {
      font-size: 2.5em;
      font-weight: 600;
      color: #fff;
      z-index: 1;
    }
    .alveolOverMap {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-50%);
    }
    /* .Overlay {
      background-color: #000;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
    } */
    &.none {
      display: none;
    }
  }

  .copyright-container {
    min-height: 120px;
    text-align: center;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000c28;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    position: relative;
    &.define {
      height: 80px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 11px;
    }

    a {
      color: #fff;
      text-decoration: underline;
      text-decoration-color: #fff;
      /* margin: 1em; */
    }
    /* .ref {
      font-size: 1.2rem;
      color: #fa280c;
      text-transform: uppercase;
      text-decoration: none;
    } */
    .hexFooter {
      top: 0;
      z-index: 2;
      position: absolute;
      transform: translateY(-75%);

      .ref {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        font-size: 0.9em;
        text-transform: uppercase;
      }
      svg {
        width: 9em;
        height: auto;
      }
    }
    .copyright {
      font-size: 16px;
    }
  }
`
