import React, { useRef } from "react"
import { Link } from "react-scroll"
import Application from "../body/offerApplication/index"
import getUrlParameter from "../dependentComponent/functions/getParamsValue"

const GetMenuItems = (menuItems, childRef) => {
  return menuItems.map((menuItem, index) => {
    return (
      <>
        {menuItem.href.includes("https") ? (
          <a
            href={menuItem.href}
            target="_blank"
            className={
              menuItem.title === "Candidature spontanée" ? "button" : ""
            }
          >
            {menuItem.title}
          </a>
        ) : (
          <Link
            activeClass="active"
            to={menuItem.href}
            key={`${menuItem.title}-${index}`}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            className={
              menuItem.title === "Candidature spontanée" ? "button" : ""
            }
            // onClick={() => {
            //   if (menuItem.title === "Candidature spontanée") {
            //     childRef.current.handleOfferApplicationVisibility(true);
            //   }
            // }}
          >
            {menuItem.title}
          </Link>
        )}
      </>
    )
  })
}

export default GetMenuItems
