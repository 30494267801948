import React from "react";
import PropTypes from "prop-types";
import "../../styles/reset.scss";
import "../../styles/layout.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const ApplicationLayout = ({ children }) => {
  return (
    <>
      <div className="layout-container">{children}</div>
    </>
  );
};

ApplicationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApplicationLayout;
