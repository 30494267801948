import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import "./styles.scss";

const StepForm = (props) => {
  const { applicationData } = props;
  const [givenName, setGivenName] = useState(
    applicationData.givenName ? applicationData.givenName : ""
  );
  const [familyName, setFamilyName] = useState(
    applicationData.familyName ? applicationData.familyName : ""
  );
  const [phone, setPhone] = useState(
    applicationData.phone ? applicationData.phone : ""
  );
  const [homePhone, setHomePhone] = useState(
    applicationData.homePhone ? applicationData.homePhone : ""
  );
  const [address, setAddress] = useState(
    applicationData.address ? applicationData.address : ""
  );
  const [email, setEmail] = useState(
    applicationData.email ? applicationData.email : ""
  );

  useEffect(() => {
    if (givenName !== "" && familyName !== "" && email.includes("@")) {
      props.setButtonState(true);
    } else {
      props.setButtonState(false);
    }
  }, [email, givenName, familyName]);

  return (
    <Form className="application-form padding-15px">
      <Row>
        <Col sm={6}>
          <label htmlFor="basic-url">Prénom*</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Prénom"
              aria-label="Prénom"
              value={givenName}
              onChange={(e) => {
                setGivenName(e.target.value);
                props.setApplicationData("givenName", e.target.value);
              }}
            />
          </InputGroup>

          <label htmlFor="basic-url">Nom*</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Nom"
              aria-label="Nom"
              value={familyName}
              onChange={(e) => {
                setFamilyName(e.target.value);
                props.setApplicationData("familyName", e.target.value);
              }}
            />
          </InputGroup>

          <label htmlFor="basic-url">Téléphone cellulaire</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Téléphone cellulaire"
              aria-label="Téléphone cellulaire"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                props.setApplicationData("phone", e.target.value);
              }}
            />
          </InputGroup>

          <label htmlFor="basic-url">Téléphone maison</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Téléphone maison"
              aria-label="Téléphone maison"
              value={homePhone}
              onChange={(e) => {
                setHomePhone(e.target.value);
                props.setApplicationData("homePhone", e.target.value);
              }}
            />
          </InputGroup>
        </Col>
        <Col sm={6}>
          <label htmlFor="basic-url">Adresse courriel*</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Adresse courriel"
              aria-label="Adresse courriel"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                props.setApplicationData("email", e.target.value);
              }}
            />
          </InputGroup>

          <label htmlFor="basic-url">Code Postal de résidence</label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Code Postal de résidence"
              aria-label="Code Postal de résidence"
              value={address.postalCode}
              onChange={(e) => {
                setAddress({ ...address, postalCode: e.target.value });
                props.setApplicationData("address", {
                  ...address,
                  postalCode: e.target.value,
                });
              }}
            />
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
};

function ApplicationFormStep1(props) {
  return (
    <Container className="offer-detail-container" fluid>
      <Row>
        {/* <Col sm={4}>
          <ContentBlock
            title={instructionText}
            isDescriptionRequiered={false}
            titleSize={"1.1rem"}
            titleFontWeight={"400"}
          />
        </Col> */}
        <Col sm={12}>
          <StepForm
            applicationData={props.applicationData}
            setApplicationData={props.setApplicationData}
            buttonState={props.buttonState}
            setButtonState={props.setButtonState}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ApplicationFormStep1;
