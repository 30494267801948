import PropTypes from "prop-types"
import React, { useRef } from "react"
import GetMenuItems from "./Menu"
import { $Header } from "./SC_Header"
import { ReactSVG } from "react-svg"
import BurgerIcon from "./BurgerIcon"
import Application from "../body/offerApplication"
import getUrlParameter from "../dependentComponent/functions/getParamsValue"

const Header = ({ clientName, menuDatas, destailPage }) => {
  const childRef = useRef()
  const [menuState, setMenuState] = React.useState(false)
  return (
    <$Header
      destailPage={destailPage ? true : false}
      menuFont={menuDatas}
      menuState={menuState}
    >
      <a href="/">
        <ReactSVG
          src={`../../../SVG/${menuDatas.logoUrl}`}
          className="logo"
          alt="logo"
        />
      </a>
      {!destailPage && (
        <>
          <div className="menu">
            <a href="https://www.stas.com/" target="_blank" className="STAS">
              STAS.COM
            </a>
            {GetMenuItems(menuDatas.menuItems, childRef)}
          </div>
          <BurgerIcon
            open={menuState}
            onClick={() => setMenuState(!menuState)}
          />
        </>
      )}
      <Application
        jobId={10688}
        email={""}
        ref={childRef}
        formParams={getUrlParameter("form") ? true : false}
      />
    </$Header>
  )
}

Header.propTypes = {
  clientName: PropTypes.string,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  clientName: ``,
  siteTitle: ``,
}

export default Header
