import styled from "styled-components";

export const $ContentBlock = styled.div`
  padding: 1em;

  label {
    font-size: small;
    max-width: 500px;
  }
  .desc {
    display: block;
    padding: 0.5em 0;
  }
  h3 {
    /* margin-bottom: 10px; */
    color: #fa280c;
    font-size: ${(props) =>
      props.dataProps.titleSize ? props.dataProps.titleSize : 18};
    font-weight: ${(props) =>
      props.dataProps.titleFontWeight ? props.dataProps.titleFontWeight : 600};
    border-bottom: 1px solid #fa280c;
    padding: 0.4em 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  p,
  .list {
    background-color: #ffffff;
    margin: 0.5em 0;
    padding: 0.5em;
    @media only screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
`;
