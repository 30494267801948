export function authHeader(applicationType = 'json', token=null) {
    if (token) {
        return {
            'content-type': 'application/'+applicationType,
            'Authorization': token
        };
    } else {
        return {
            'content-type': 'application/'+applicationType
        };
    }
}

export const authHelper = {
    authHeader,
};

