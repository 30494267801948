import PropTypes from "prop-types";
import React from "react";
import { $ContentBlock } from "./SC_ContentBlock";

function ContentBlock(props) {
  let descriptionReplace;
  if (
    props.isDescriptionRequiered &&
    !props.description &&
    !props.contactViaLinkedin
  )
    return null;
  if (props.description) {
    descriptionReplace = props.description.split(";");
  }
  return (
    <$ContentBlock dataProps={props}>
      {props.title ? <h3>{props.title}</h3> : null}
      {!props.subtitle ? null : (
        <label style={{ fontSize: props.subtitleSize }}>{props.subtitle}</label>
      )}
      {props.description ? (
        <p>
          {descriptionReplace.map((desc, i) => (
            <span key={`${desc}${i}`} className="desc">
              {desc}
            </span>
          ))}
        </p>
      ) : null}
      {props.contactViaLinkedin ? (
        <a href={props.contactViaLinkedin} target="_blank" rel="noopener">
          <img
            src="/icons/linkedin-contact-via.jpg"
            alt="linkedin"
            width="150"
            height="50"
          />
        </a>
      ) : null}
    </$ContentBlock>
  );
}

ContentBlock.propTypes = {
  titleSize: PropTypes.string,
  subtitleSize: PropTypes.string,
  titleFontWeight: PropTypes.string,
  isDescriptionRequiered: PropTypes.bool,
};

ContentBlock.defaultProps = {
  siteTitle: "1.75rem",
  subtitleSize: `14px`,
  titleFontWeight: `bold`,
  isDescriptionRequiered: true,
};

export default ContentBlock;
